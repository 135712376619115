import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { parse, format } from 'date-fns';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import './Events.css';
import interestLogo from '../assets/interest_logo.svg';
import brefyLogo from '../assets/logoBrefy.svg';
import meetingLogo from '../assets/meeting_logo.svg';
import briefLogo from '../assets/briefcase_logo.svg';
import thumbsUp from '../assets/thumbs_up.svg';
import thumbsUpClicked from '../assets/thumbs_up_clicked.svg';
import thumbsDown from '../assets/thumbs_down.svg';
import thumbsDownClicked from '../assets/thumbs_down_clicked.svg';
import noResultsLogo from '../assets/no_results_logo.svg';
import Footer from '../components/Footer'; 

const Events = () => {
  const location = useLocation();
  const [insights, setInsights] = useState({});
  const [attendeeDetails, setAttendeeDetails] = useState([]);
  const [eventData, setEventData] = useState({});
  const [conversationStarters, setConversationStarters] = useState({});
  const [userName, setUserName] = useState({});
  const [selectFeedback, setFeedback] = useState(null);
  const [activeTab, setActiveTab] = useState('summary');
  const [loading, setLoading] = useState(false);
  const eventId = location.pathname.split('/').pop(); 
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const storedUserInfo = sessionStorage.getItem('user_info');
  const navigate = useNavigate();
  
  const userInfo = JSON.parse(storedUserInfo);

  const getGravatarUrl = (email, size = 200) => {
    const hash = CryptoJS.MD5(email.trim().toLowerCase()).toString();
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=identicon`;
  };

  const checkToken = async () => {
    const frontendToken = localStorage.getItem('JWT');  

    try {
        const response = await axios.post('/api/check-token', { token: frontendToken });
        
        if (response.status === 200) {
            console.log('Tokens match');
        }
    } catch (error) {
        if (error.response.status === 401) {
            console.log('Redirecting to login');
            localStorage.removeItem('JWT');
            sessionStorage.removeItem('user_id');
            sessionStorage.removeItem('user_info');
            navigate('/'); 
        }
    }
  };

  const handleClick = (feedback) => {
    setFeedback(prevFeedback => prevFeedback === feedback ? null : feedback);
  };

  const formattedStart = insights?.start;

  const splitDateTime = (dateTimeString) => {
    if (!dateTimeString) return { date: 'Invalid date', time: '' };

    const dateObj = new Date(dateTimeString);

    if (isNaN(dateObj.getTime())) return { date: 'Invalid date', time: '' };

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        month: 'long',   
        day: '2-digit', 
        year: 'numeric'
    });
    const formattedDate = dateFormatter.format(dateObj);

    const timeFormatter = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',   
        minute: '2-digit',  
        hour12: true     
    });
    const formattedTime = timeFormatter.format(dateObj);

    return { date: formattedDate, time: formattedTime };
  };

  const { date, time } = splitDateTime(formattedStart);

  const attendees = Array.isArray(insights?.attendees) ? insights.attendees : [];
  const firstAttendee = attendees.length > 0 ? attendees[0].name : 'Unknown';
  const otherAttendeesCount = attendees.length > 1 ? attendees.length - 1 : 0;

  useEffect(() => {
    checkToken();
    const jwt = localStorage.getItem('JWT');
    if (jwt) {
        axios.post('/api/event/insights', { event_id: eventId })
        .then((response) => {
            const data = response.data;
            setInsights(data.event_data);
            setUserName(data.user_name);
            setAttendeeDetails(data.detailed_attendees);
        })
        .catch((error) => {
            console.error('Error fetching event insights:', error);
        });
    } else {
      console.log("User ID not found");
      localStorage.removeItem('JWT');
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('user_info');
      navigate('/');
      setLoading(false);
      return;
    }
  }, [eventId, location.state]);

  const handleAttendeeClick = async (attendee) => {
    if (selectedAttendee?.email === attendee.email) {
        setSelectedAttendee(null);
    } else {
        setSelectedAttendee(attendee);
        if (!conversationStarters[attendee.email]) {
            try {
                setLoading(true);
                const response = await axios.get('/api/event/insights/conversation_starters', {
                    params: { 
                        attendee_email: attendee.email, // Convert the selected attendee's details to string
                        meeting_name: insights.name,
                        meeting_description: insights.description,
                        meeting_id: insights.id
                    }
                });
                const starters = response.data;
                setConversationStarters(prev => ({ ...prev, [attendee.email]: starters }));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching conversation starters:', error);
            }
        }
    }
  };
  const conversationStartersData = conversationStarters[selectedAttendee?.email] || { conversation_starters: 'No Conversation starters available' };
  const conversationStartersList = conversationStartersData.conversation_starters;



  return (
    <div className="bg-custom-grey min-h-screen">
        <nav className="bg-white border-gray-200 border-b dark:bg-gray-900">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <Link to="/dashboard" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={brefyLogo} alt="Brefy Logo" className="rounded-lg mr-4"/>
                </Link>
                <div className="flex items-center">
                    <button type="button" className="flex items-center rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                        <img src={getGravatarUrl(userInfo.user_id, 200)} alt="Person Logo" className="w-8 h-8 rounded-full mr-2" />
                        <h2 className="text-xxxl font-semibold">{userName.first_name}</h2>
                    </button>
                </div>
            </div>
        </nav>
        <div className="md:container md:mx-auto mt-4 md:mt-20 mb-20">
            <div className="mx-8 md:mx-0 bg-white shadow-md rounded-lg p-4 flex flex-col items-center justify-center mb-6">
                <img src={meetingLogo} alt="Meeting Logo" className="w-16 h-16 rounded-lg mr-4"/>
                <h1 className="text-xl md:text-3xl text-custom-darkblue mt-2 md:my-4 mb-0 ml-4">Pre-meeting insights</h1>
                <>
                    {attendees.length > 0 ? (
                        <h1 className="text-xl md:text-3xl text-custom-darkblue text-center font-bold mb-4 ml-4">
                        {firstAttendee} with {otherAttendeesCount} {otherAttendeesCount === 1 ? 'other' : 'others'} on {date} at {time}.
                        </h1>
                    ) : (
                        <h1 className="text-xl md:text-3xl text-custom-darkblue text-center font-bold mb-4 ml-4">
                        No attendees for {date} at {time}.
                        </h1>
                    )}
                </>
            </div>
            <div className="block lg:hidden">
                    <div className="flex w-full items-center justify-center">
                        {/* ATTENDEES */}
                        <div className="flex-none w-full">
                            <ul>
                            {attendees.map((attendee, index) => (
                                <li key={index} className="mb-4">
                                <div className={
                                    `flex-col shadow-sm rounded-md mx-8 md:mx-0 bg-white shadow-md rounded p-2 flex flex-row items-center border hover:bg-custom-bg transition duration-200 cursor-pointer 
                                    ${selectedAttendee?.email === attendee?.email ? 'bg-custom-bg focus:outline-none c rounded-full border border-custom-blue' : 'bg-white'
                                }`}>
                                    <div className="flex flex-none self-start mt-2 w-full" onClick={() => handleAttendeeClick(attendee)}>
                                        <img src={getGravatarUrl(attendee.email, 200)} alt="Person Logo" className="w-12 h-12 rounded-full mr-4" />
                                        <div className="flex-1 text-left">
                                        <div className="flex flex-col mb-1">
                                            <h2 className="text-xl font-bold">
                                                {attendee.name}&nbsp;
                                            </h2>
                                            <h2 className="text-base font-normal">
                                                ({attendee.email})
                                            </h2>
                                        </div>
                                        <p className="text-gray-700">{attendeeDetails.find(detail => detail?.email === attendee?.email)?.position || 'No Title'}</p>
                                        </div>
                                    </div>
                                    {selectedAttendee?.email === attendee?.email && (
                                    <>
                                    <div className="w-full mt-12">
                                        <div className="flex justify-center mb-2">
                                            <div className="flex bg-gray-100 rounded-t-lg w-full justify-center">
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setActiveTab('summary');
                                                      }}                            
                                                    className={`flex-grow py-4 font-semibold text-xl rounded-t-lg mt-0 focus:outline-none transition duration-200 ${activeTab === 'summary' ? 'text-custom-blue bg-white' : 'text-gray-500'}`}
                                                >
                                                    Summary
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setActiveTab('company');
                                                      }}
                            
                                                    className={`flex-grow sm:px-12 font-semibold text-xl rounded-t-lg mt-0 focus:outline-none transition duration-200 ${activeTab === 'company' ? 'text-custom-blue bg-white' : 'text-gray-500'}`}
                                                >
                                                    Company
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="block">
                                        {activeTab === 'summary' && (
                                            <>
                                                <div className="bg-white rounded-lg  flex flex-col justify-end border p-8">
                                                    <div className="flex items-center">
                                                        <img src={briefLogo} alt="Briefcase Logo" className="bg-custom-bg w-8 h-8 rounded-lg self-center"/>
                                                        <h2 className="text-lg font-bold ml-4">Work Experience</h2>
                                                    </div>
                                                    <div className="flex-1 mb-4 mt-2">
                                                        <p className="text-gray-700 text-sm">Current CEO of {attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.company || 'No Company'}. Previously, worked at DEF LLC., GHI Corporation & JKL Inc.  Lorem ipsum dolor sit amet consectetur. Mauris fusce laoreet turpis ornare velit. Diam sed ac id magna semper. Elementum dictumst mattis phasellus elit. Ac eu sem scelerisque a fusce sit.</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <img src={interestLogo} alt="Interest Logo" className="bg-custom-bg w-8 h-8 rounded-lg self-center"/>
                                                        <h2 className="text-lg font-bold ml-4">Recent Interests & Activities</h2>
                                                    </div>
                                                    <div className="flex-1 mb-8 mt-2">
                                                        <p className="text-gray-700 text-sm">Recently shared on LinkedIn about their vision for the future of Communication and Technology. Connected with C. Siphron of XYZ Technologies.</p>
                                                    </div>
                                                    {attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.linkedin_url && (
                                                        <a href={attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.linkedin_url} className="text-custom-blue font-bold hover:underline self-center">
                                                            <button type="button" onClick={(e) => e.stopPropagation()} className="text-white bg-custom-purple font-medium rounded-full text-sm px-5 py-2.5 me-2">
                                                                Go to LinkedIn Bio
                                                            </button>
                                                        </a>
                                                    )}
                                                </div>
                                                <div>
                                                    <h2 className="text-base text-custom-black font-semibold mb-4 mt-4">Suggested Key Points for Engagement</h2>
                                                    <div className="mb-2 space-y-4">
                                                        {/* CONVERSATION STARTER */}
                                                        <div className="bg-white shadow-sm rounded-lg p-2 flex flex-col sm:flex-row items-center border">
                                                            <div className="flex-1 text-left">
                                                            <h2 className="text-xl font-bold mb-4">Conversation Starter</h2>
                                                            {loading ? (
                                                                <div className="flex justify-center items-center">
                                                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                                    </svg>
                                                                </div>
                                                            ) : (
                                                                <ul className="list-disc list-inside space-y-2">
                                                                    {typeof conversationStartersList === 'string' ? (
                                                                        conversationStartersList.split('\n').filter(starter => starter.trim() !== '').map((starter, index) => (
                                                                            <li key={index}>{starter}</li>
                                                                        ))
                                                                    ) : (
                                                                        <p>Invalid conversation starters data</p>
                                                                    )}
                                                                </ul>
                                                            )}
                                                            </div>
                                                        </div>
                                                        {/* QUESTIONS TO ASK
                                                        <div className="bg-white shadow-sm rounded-lg p-4 flex flex-col sm:flex-row items-center border">
                                                            <div className="flex-1 text-left">
                                                                <h2 className="text-xl font-bold mb-4">Questions to Ask</h2>
                                                                <ul className="list-disc list-inside space-y-2">
                                                                    <li>Lorem ipsum dolor sit amet consectetur. Convallis mauris amet est nulla blandit. </li>
                                                                    <li>Ornare sed pretium arcu quis ut tristique mattis. Eros justo gravida risus tempus proin. </li>
                                                                    <li>Mi hac a felis ultrices morbi convallis massa porta ac.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        TOPICS TO AVOID
                                                        <div className="bg-white shadow-sm rounded-lg p-4 flex flex-col sm:flex-row items-center border">
                                                            <div className="flex-1 text-left">
                                                                <h2 className="text-xl font-bold mb-4">Topics to Avoid</h2>
                                                                <ul className="list-disc list-inside space-y-2">
                                                                    <li>Lorem ipsum dolor sit amet consectetur. Convallis mauris amet est nulla blandit. </li>
                                                                    <li>Ornare sed pretium arcu quis ut tristique mattis. Eros justo gravida risus tempus proin. </li>
                                                                    <li>Mi hac a felis ultrices morbi convallis massa porta ac.</li>
                                                                </ul>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {activeTab === 'company' && (
                                            <>
                                                <div className="bg-white rounded-lg flex flex-col border justify-end p-8">
                                                    <div className="flex-1 mb-4">
                                                        <p className="text-gray-700 text-sm">{attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.company_description || 'No Description'}</p>
                                                    </div>
                                                    <h2 className="text-base font-semibold text-custom-black">Industries and Specialties</h2>
                                                    <div>
                                                        <ul className="list-disc list-inside space-y-2">
                                                            {attendeeDetails
                                                                .find(detail => detail?.email === selectedAttendee?.email)?.specialties &&
                                                                attendeeDetails.find(detail => detail?.email === selectedAttendee?.email).specialties.map((specialty, index) => (
                                                                    <button
                                                                        key={index}
                                                                        type="button"
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        className="py-1 px-2 me-2 mb-2 text-medium font-base text-custom-blue focus:outline-none bg-custom-bg rounded-full border border-custom-blue hover:bg-gray-100"
                                                                    >
                                                                        {specialty}
                                                                    </button>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row items-center justify-center mt-4">
                                                    {attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.company_linkedin_url && (
                                                        <a href={attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.company_linkedin_url} className="text-custom-blue font-bold hover:underline self-center">
                                                            <button type="button" onClick={(e) => e.stopPropagation()} className="text-white bg-custom-purple text-sm font-bold rounded-full px-4 py-2 me-2 mb-2">
                                                                Go to LinkedIn Bio
                                                            </button>
                                                        </a>
                                                    )}
                                                    <a href="" className="text-custom-blue font-bold hover:underline self-center">
                                                        <button type="button" onClick={(e) => e.stopPropagation()} className="text-custom-purple bg-white border text-sm font-bold  border-custom-purple rounded-full px-4 py-2 me-2 mb-2">The Grid Profile</button>
                                                    </a>
                                                </div>
                                                
                                            </>
                                        )}
                                    </div>
                                    </>
                                    )}
                                </div>
                                </li>
                            ))}
                            </ul>
                        </div>
                        {/* INTERESTS INFORMATION */}
                        
                    </div>
            </div>
            <div className="hidden lg:block mx-8 md:mx-0 bg-white shadow-md rounded p-8">
                <div className="mb-8 space-y-4 mx-auto">
                    <div className="hidden md:block p-4">
                        <div className="flex items-end justify-between border-b border-gray-300 mb-2">
                            <div className="flex-none font-semibold text-xl text-gray-500 opacity-70 mb-4">Attendees</div>
                            <div className="flex bg-gray-100 rounded-t-lg">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActiveTab('summary');
                                      }}            
                                    className={`py-4 px-6 font-semibold text-xl rounded-t-lg mt-0 shadow-md focus:outline-none transition duration-200 ${activeTab === 'summary' ? 'text-custom-blue bg-white' : 'text-gray-500'}`}
                                >
                                    Summary
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActiveTab('company');
                                      }}
            
                                    className={`py-4 px-8 font-semibold text-xl rounded-t-lg mt-0 shadow-md focus:outline-none transition duration-200 ${activeTab === 'company' ? 'text-custom-blue bg-white' : 'text-gray-500'}`}
                                >
                                    Company
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex gap-10 xl:gap-20">
                        {/* ATTENDEES */}
                        <div className="flex-none w-full md:flex-none lg:w-[375px] xl:w-[475px] ">
                            <ul>
                            {attendees.map((attendee, index) => (
                                <li key={index} className="mb-4" onClick={() => handleAttendeeClick(attendee)}>
                                <div className={
                                    `shadow-sm rounded-md md:rounded-full p-4 flex flex-row items-center border hover:bg-custom-bg transition duration-200 cursor-pointer 
                                    ${selectedAttendee?.email === attendee.email ? 'bg-custom-bg focus:outline-none c rounded-full border border-custom-blue' : 'bg-white'
                                }`}>

                                    <img src={getGravatarUrl(attendee.email, 200)} alt="Person Logo" className="w-12 h-12 rounded-full mr-4" />
                                    <div className="flex-1 text-left">
                                    <div className="flex flex-col xl:flex-row text-xxxl font-semibold">
                                        <h2 className="text-xxxl font-semibold">
                                            {attendee.name}&nbsp;
                                        </h2>
                                        <h2 className="text-xxxl font-semibold">
                                            ({attendee.email})
                                        </h2>
                                    </div>
                                    <p className="text-gray-700">{attendeeDetails.find(detail => detail?.email === attendee?.email)?.position || 'No Title'}</p>
                                    </div>
                                </div>
                                </li>
                            ))}
                            </ul>
                        </div>
                        {/* INTERESTS INFORMATION */}
                        <div className="hidden md:block w-full">
                        {activeTab === 'summary' && (
                            <>
                            {selectedAttendee ? (
                                <>
                                <div className="bg-white shadow-sm rounded-lg p-10 flex flex-col border justify-end">
                                    <div className="flex items-center">
                                        <img src={briefLogo} alt="Briefcase Logo" className="bg-custom-bg w-16 h-16 rounded-lg mb-4 sm:mb-0"/>
                                        <h2 className="text-xl font-bold mb-2 ml-8">Work Experience</h2>
                                    </div>
                                    <div className="flex-1 ml-24 mb-16 mt-2">
                                        <p className="text-gray-700">Current CEO of {attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.company || 'No Company'}. Previously, worked at DEF LLC., GHI Corporation & JKL Inc.  Lorem ipsum dolor sit amet consectetur. Mauris fusce laoreet turpis ornare velit. Diam sed ac id magna semper. Elementum dictumst mattis phasellus elit. Ac eu sem scelerisque a fusce sit.</p>
                                    </div>
                                    <div className="flex items-center">
                                        <img src={interestLogo} alt="Interest Logo" className="bg-custom-bg w-16 h-16 rounded-lg mb-4 sm:mb-0"/>
                                        <h2 className="text-xl font-bold mb-2 ml-8">Recent Interests & Activities</h2>
                                    </div>
                                    <div className="flex-1 ml-24 mb-16 mt-2">
                                        <p className="text-gray-700">Recently shared on LinkedIn about their vision for the future of Communication and Technology. Connected with C. Siphron of XYZ Technologies.</p>
                                    </div>
                                    {attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.linkedin_url && (
                                        <a href={attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.linkedin_url} className="text-custom-blue font-bold hover:underline self-center">
                                            <button type="button" onClick={(e) => e.stopPropagation()} className="text-white bg-custom-purple font-medium rounded-full text-sm px-5 py-2.5 me-2">
                                                Go to LinkedIn Bio
                                            </button>
                                        </a>
                                    )}
                                </div>
                                <div>
                                    <h2 className="text-2xl text-custom-black font-bold mb-8 mt-8">Suggested Key Points for Engagement</h2>
                                    <div className="mb-8 space-y-4">
                                        {/* CONVERSATION STARTER */}
                                        <div className="bg-white shadow-sm rounded-lg p-4 flex flex-col sm:flex-row items-center border">
                                            <div className="flex-1 text-left">
                                            <h2 className="text-xl font-bold mb-4">Conversation Starter</h2>
                                            {loading ? (
                                                <div className="flex justify-center items-center">
                                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                    </svg>
                                                </div>
                                            ) : (
                                                <ul className="list-disc list-inside space-y-2">
                                                    {typeof conversationStartersList === 'string' ? (
                                                        conversationStartersList.split('\n').filter(starter => starter.trim() !== '').map((starter, index) => (
                                                            <li key={index}>{starter}</li>
                                                        ))
                                                    ) : (
                                                        <p>Invalid conversation starters data</p>
                                                    )}
                                                </ul>
                                            )}
                                            </div>
                                        </div>
                                        {/* QUESTIONS TO ASK */}
                                        <div className="bg-white shadow-sm rounded-lg p-4 flex flex-col sm:flex-row items-center border">
                                            <div className="flex-1 text-left">
                                                <h2 className="text-xl font-bold mb-4">Questions to Ask</h2>
                                                <ul className="list-disc list-inside space-y-2">
                                                    <li>Lorem ipsum dolor sit amet consectetur. Convallis mauris amet est nulla blandit. </li>
                                                    <li>Ornare sed pretium arcu quis ut tristique mattis. Eros justo gravida risus tempus proin. </li>
                                                    <li>Mi hac a felis ultrices morbi convallis massa porta ac.</li>
                                                    {/* {insights.questions.map((starter, index) => (
                                                        <li key={index}>{starter}</li>
                                                    ))} */}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* TOPICS TO AVOID */}
                                        <div className="bg-white shadow-sm rounded-lg p-4 flex flex-col sm:flex-row items-center border">
                                            <div className="flex-1 text-left">
                                                <h2 className="text-xl font-bold mb-4">Topics to Avoid</h2>
                                                <ul className="list-disc list-inside space-y-2">
                                                    <li>Lorem ipsum dolor sit amet consectetur. Convallis mauris amet est nulla blandit. </li>
                                                    <li>Ornare sed pretium arcu quis ut tristique mattis. Eros justo gravida risus tempus proin. </li>
                                                    <li>Mi hac a felis ultrices morbi convallis massa porta ac.</li>
                                                    {/* {insights.topicsAvoid.map((starter, index) => (
                                                        <li key={index}>{starter}</li>
                                                    ))} */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                            ) : (
                            <>
                            <div className="mt-20 flex flex-col items-center justify-center content-center">
                                <img src={noResultsLogo} alt="No Results Logo" className="w-8 h-8 rounded-full mr-2" />
                                <div className="text-custom-darkgrey text-lg font-semibold">No attendee selected</div>
                            </div>
                            </>
                            )}
                            </>
                        )}
                        {activeTab === 'company' && (
                            <>
                            {selectedAttendee ? (
                                <>
                                <div className="bg-white shadow-sm rounded-lg p-10 flex flex-col border justify-end">
                                    <div className="flex-1 mb-20">
                                        <p className="text-gray-700 text-lg">{attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.company_description || 'No Description'}</p>
                                    </div>
                                    <h2 className="text-xl font-semibold text-custom-black">Industries and Specialties</h2>
                                    <div>
                                        <ul className="list-disc list-inside space-y-2">
                                            {attendeeDetails
                                                .find(detail => detail?.email === selectedAttendee?.email)?.specialties &&
                                                attendeeDetails.find(detail => detail?.email === selectedAttendee?.email).specialties.map((specialty, index) => (
                                                    <button
                                                        key={index}
                                                        type="button"
                                                        onClick={(e) => e.stopPropagation()}
                                                        className="py-2.5 px-5 me-2 mb-2 text-medium font-base text-custom-blue focus:outline-none bg-custom-bg rounded-full border border-custom-blue hover:bg-gray-100"
                                                    >
                                                        {specialty}
                                                    </button>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-20 items-center justify-center mt-8">
                                    {attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.company_linkedin_url && (
                                        <a href={attendeeDetails.find(detail => detail?.email === selectedAttendee?.email)?.company_linkedin_url} className="text-custom-blue font-bold hover:underline self-center">
                                            <button type="button" onClick={(e) => e.stopPropagation()} className="text-white bg-custom-purple text-base font-bold rounded-full px-6 xl:px-16 py-2 me-2 mb-2">
                                                Go to LinkedIn Bio
                                            </button>
                                        </a>
                                    )}
                                    <a href="" className="text-custom-blue font-bold hover:underline self-center">
                                        <button type="button" onClick={(e) => e.stopPropagation()} className="text-custom-purple bg-white border text-base font-bold  border-custom-purple rounded-full  px-6 xl:px-16 py-2 me-2 mb-2">The Grid Profile</button>
                                    </a>
                                </div>
                                </>
                            ) : (
                            <>
                            <div className="mt-20 flex flex-col items-center justify-center content-center">
                            <img src={noResultsLogo} alt="No Results Logo" className="w-8 h-8 rounded-full mr-2" />
                                <div className="text-custom-darkgrey text-lg font-semibold">No attendee selected</div>
                            </div>
                            </>
                            )}
                            </>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-col items-center justify-center mb-16">
            <h1 className="text-xl md:text-3xl font-bold opacity-25 mb-8 text-center">
                Is this information correct? <br />
                Give us your feedback!</h1>
            <div className="flex flex-col items-center">
                <div className="flex flex-row gap-6 mb-10">
                    <img
                        src={selectFeedback === 'up' ? thumbsUpClicked : thumbsUp}
                        alt="Thumbs Up"
                        className={`w-8 h-8 md:w-12 md:h-12 rounded-full cursor-pointer ${selectFeedback === 'up' ? 'opacity-100' : 'opacity-50'}`}
                        onClick={() => handleClick('up')}
                    />
                    <img
                        src={selectFeedback === 'down' ? thumbsDownClicked : thumbsDown}
                        alt="Thumbs Down"
                        className={`w-8 h-8 md:w-12 md:h-12 rounded-full cursor-pointer ${selectFeedback === 'down' ? 'opacity-100' : 'opacity-50'}`}
                        onClick={() => handleClick('down')}
                    />
                </div>

                {selectFeedback === 'up' && (
                    <h1 className="text-base font-bold text-custom-green mb-8 opacity-50">Thank you for your feedback!</h1>
                )}

                {selectFeedback === 'down' && (
                    <form className="max-w-sm mx-auto">
                        <p className="text-gray-700 text-lg">What information is incorrect or unclear? Let us know!</p>
                        <div className="mb-2">
                            <input
                                type="text"
                                className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Email address"
                            />
                        </div>
                        <div className="mb-2">
                            <textarea
                                id="description-input"
                                className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 h-32 resize-none"
                                placeholder="Description"
                            />
                        </div>
                    </form>
                )}
            </div>
        </div>
        
        {/* <Footer /> */}
    </div>
  );
};

export default Events;
