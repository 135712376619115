import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import attachmentLogo from '../assets/attachment_logo.svg';
import brefyLogo from '../assets/logoBrefy.svg';
import personLogo from '../assets/person_logo.svg';
import meetingLogo from '../assets/meeting_logo.svg';
import CryptoJS from 'crypto-js';
import noEventsLogo from '../assets/no_events_logo.svg';
import videocamLogo from '../assets/videocam_logo.svg';
import accountLogo from '../assets/account_logo.svg';
import accountBlackLogo from '../assets/account_black_logo.svg';
import calendarLogo from '../assets/calendar_logo.svg';
import calendarBlackLogo from '../assets/calendar_black_logo.svg';
import Footer from '../components/Footer'; 
// import Modal from '../components/Modal'; 

function extractUserInfoFromJWT(jwt) {
  try {
    const decoded = jwtDecode(jwt);
    const userInfo = {
      user_id: decoded.user_id,
      picture_url: decoded.picture, 
      given_name: decoded.given_name,
      last_name: decoded.family_name, 
    };
    return userInfo;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
}

const Dashboard = () => {
  const [events, setEvents] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [userId, setUserId] = useState(null);
  const [userPictureLink, setUserPicture] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userName, setUserName] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [activeTab, setActiveTab] = useState('meetings');
  const [firstVisit, setFirstVisit] = useState(false);
  const navigate = useNavigate();

  const getGravatarUrl = (email, size = 200) => {
    const hash = CryptoJS.MD5(email.trim().toLowerCase()).toString();
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=identicon`;
  };

  const fetchData = async () => {
    setLoading(true);
    setShowContent(false);

    const storedUserId = sessionStorage.getItem('user_id');
    const storedUserInfoString = sessionStorage.getItem('user_info');
    const queryParams = new URLSearchParams(window.location.search);
    const page = queryParams.get('page') || 1;
    const jwt = localStorage.getItem('JWT');
    if (jwt) {
      const userInfoFromJWT = extractUserInfoFromJWT(jwt);
      sessionStorage.setItem('user_id', userInfoFromJWT.user_id);
      sessionStorage.setItem('user_info', JSON.stringify(userInfoFromJWT)); 
      setUserId(userInfoFromJWT.user_id);
      setUserPicture(getGravatarUrl(userInfoFromJWT.user_id, 200));
      setUserFirstName(userInfoFromJWT.given_name);
      setUserLastName(userInfoFromJWT.last_name);
    } else {
      console.log("User ID not found");
      localStorage.removeItem('JWT');
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('user_info');
      navigate('/');
      setLoading(false);
      return;
    }
    if (storedUserId) {
      setUserId(storedUserId);
    } 

    if (storedUserInfoString) {
      const storedUserInfo = JSON.parse(storedUserInfoString);
      setUserPicture(getGravatarUrl(storedUserInfo.user_id, 200));
      setUserFirstName(storedUserInfo.given_name);
      setUserLastName(storedUserInfo.last_name);
    }

    // Fetch events if userId is available
    if (storedUserId || userId) {
      try {
        const response = await axios.get(`/api/calendar`, {
          params: { user_id: userId || storedUserId, page}
        });

        const data = response.data;
        console.log(data)

        if (!Array.isArray(data.events)) {
          console.error('Invalid events data:', data.events);
          setEvents([]);
        } else {
          const fullName = `${data.user_name.first_name} ${data.user_name.last_name}`;
          setUserName(fullName);
          setEvents(data.events);
        }

        if (typeof data.total_pages !== 'number') {
          console.error('Invalid total pages data:', data.total_pages);
          setTotalPages(1);
        } else {
          setTotalPages(data.total_pages);
        }

      } catch (error) {
        console.error('Error fetching events:', error);
        setEvents([]);
      } finally {
        setLoading(false);
        setShowContent(true);
      }
    }
  };

  const checkToken = async () => {
    const frontendToken = localStorage.getItem('JWT');  

    try {
        const response = await axios.post('/api/check-token', { token: frontendToken });
        
        if (response.status === 200) {
            console.log('Tokens match');
        }
    } catch (error) {
        if (error.response.status === 401) {
            console.log('Redirecting to login');
            localStorage.removeItem('JWT');
            sessionStorage.removeItem('user_id');
            sessionStorage.removeItem('user_info');
            navigate('/');  // Redirect to login page
        }
    }
  };

  const testEndpoint = async () => {
    try {
        const user_email = userId;

        const response = await axios.post('/api/send-welcome-email', {
            user_email: user_email
        });

        if (response.status === 200) {
            alert(`Success! : ${response.data.message}
            response: ${response.data}`); 
        } else {
            alert(`Failed to send welcome email: ${response.data.error}
                  response: ${response.data}`);
        }
    } catch (error) {
        if (error.response) {
            alert(`Error: ${error.response.data.error},
                  res: ${error.response.data}`);
        } else if (error.request) {
            alert("No response from server. Please try again later.");
        } else {
            alert(`Unexpected error: ${error.message}`);
        }
    }
};

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let page = parseInt(queryParams.get('page'), 10) || 1;
  
    if (page < 1 || page > totalPages) {
      page = 1; 
      navigate(`/dashboard?page=${page}`); 
    } else {
      setCurrentPage(page);
      const isFirstVisit = !sessionStorage.getItem('first_visit')
      fetchData();
      checkToken();
      if (isFirstVisit && (userId || sessionStorage.getItem('user_id'))) {
        handleUpdateEvents();
        sessionStorage.setItem('first_visit', 'true');
      }
    }
  }, [window.location.search, totalPages]); 

  const handleLogout = async () => {
    try {
        await axios.post('/api/logout', {}, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        // Clear local storage and session storage
        localStorage.removeItem('JWT');
        sessionStorage.removeItem('user_id');
        sessionStorage.removeItem('user_info');
        sessionStorage.removeItem('first_visit');
        navigate("/");
    } catch (error) {
        console.error("Logout failed", error);
    }
  };

  // const [showModal, setShowModal] = useState(false);
  // const [lastCheckedTime, setLastCheckedTime] = useState(new Date().toISOString());

  // useEffect(() => {
  //   const checkForUpdates = async () => {
  //     try {
  //       const lastCheckedDate = new Date(lastCheckedTime);
  //       const response = await axios.post('/api/check-updates', {
  //           user_id: userId
  //       });

  //       const lastUpdate = new Date(response.data.lastUpdate + 'Z');
  //       const lastUpdateNew = new Date(response.data.lastUpdate).toISOString()

  //       if (lastUpdate.getTime() > lastCheckedDate.getTime()) {
  //           setShowModal(true);
  //           setLastCheckedTime(lastUpdate.toISOString()); // Update the last checked time
  //       }
  //     } catch (error) {
  //         console.error("Error checking for updates:", error);
  //     }
  //   };

  //   checkForUpdates();

  //   // Set up interval to run checkForUpdates every 30 seconds (60000 ms)
  //   const intervalId = setInterval(checkForUpdates, 30000);

  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, [lastCheckedTime, userId]);

  const handleUpdateEvents = async () => {
    try {
      // setShowModal(false)
      setLoading(true);
      await axios.post('/api/update-events', {
        user_id: userId || sessionStorage.getItem('user_id')
      });
      fetchData();
    } catch (error) {
      console.error('Error updating events:', error);
    }
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    // console.log("DEBUGGING FOR DATE STRING")
    // console.log(dateString)
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(date);
  };

  useEffect(() => {
    if (!loading) {
      setShowContent(true);
    }
  }, [loading]);

  const extractEventIdFromLink = (link) => {
    const decodedLink = decodeURIComponent(link); 
  
    const regex = /eid=([a-zA-Z0-9_-]+)/; 
    const match = decodedLink.match(regex);

    if (match) {
      const base64Id = match[1];
      const decodedId = atob(base64Id); // Decode Base64 to get the event ID
      return decodedId.split(' ')[0]; // Extract the event ID from the decoded string
    }
    return null;
  };

  const getDisplayDate = (formattedStart) => {
    const eventDate = new Date(formattedStart);
    if (isNaN(eventDate.getTime())) {
      console.error("Invalid date:", formattedStart);
      return "Invalid date";
    }
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
  
    // Remove the time part of the dates for accurate comparison
    today.setHours(0, 0, 0, 0);
    tomorrow.setHours(0, 0, 0, 0);
  
    if (eventDate.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (eventDate.toDateString() === tomorrow.toDateString()) {
      return 'Tomorrow';
    } else {
      // Format the date in a readable format (e.g., August 14)
      return eventDate.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      });
    }
  };

  const handleViewEventInsights = async (event) => {
  const eventId = extractEventIdFromLink(event.html_link);
  
    if (eventId) {
      try {
          const response = await axios.post('/api/event/insights', { 
              event_id: eventId, 
              event_data: event,
              user_id: userId,
              user_name: userName,
          });
          navigate(`/events/${eventId}`, { 
              state: { 
                  insights: response.data, 
                  eventData: event,
                  userId: userId,
                  userName: userName,
              }
          });
      } catch (error) {
          console.error('Error fetching insights:', error);
      }
  } else {
      console.error('Event ID not found');
  }
  };

  return (
    <div className="min-h-screen">
      {/* HEADER */}
      <nav className="bg-white border-gray-200 border-b dark:bg-gray-900">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <Link to="/dashboard" className="flex items-center space-x-3 rtl:space-x-reverse">
                <img src={brefyLogo} alt="Brefy Logo" className="rounded-lg mr-4"/>
            </Link>
            <button onClick={handleLogout} className="text-white bg-custom-darkblue hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-xs px-10 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Sign out</button>
          </div>
      </nav>
      {/* BODY */}
      <div className="md:container md:mx-auto mt-20 mb-20">
        <div className="flex flex-col max-w-screen-xl mx-auto md:flex-row justify-center md:justify-start">
          {/* PROFILE CARD */}
          <div className="flex flex-col md:ml-8 md:mr-32 text-left min-w-max">
            <div className="flex flex-row self-center md:flex-col md:self-start mb-8 md:mb-0">
              <img src={userPictureLink} alt="Person Logo" className="w-24 h-24 rounded-full mr-4 md:mr-2 self-center md:self-auto"/>
              <div className="flew-col md:flex-none">
                <h1 className="text-3xl font-semibold mt-8 mb-1">{userName}</h1>
                <h2 className="text-base text-custom-darkgrey mb-8">{userId}</h2>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row justify-center md:flex-col">
                <div className="flex flex-row items-center mb-4 mr-10 md:mr-0">
                  <img src={activeTab === 'meetings' ? calendarLogo : calendarBlackLogo} alt="Calendar Logo" className="w-8 h-8 rounded-full cursor-pointer" onClick={() => setActiveTab('meetings')}/>
                  <h1 className={`text-base ml-2 cursor-pointer ${activeTab === 'meetings' ? 'text-custom-brightgreen font-semibold' : ''}`} onClick={() => setActiveTab('meetings')}>Meetings</h1>
                </div>
                {/* Account Overview Tab */}
                <div className="flex flex-row items-center mb-4 md:mr-0">
                  <img src={activeTab === 'account' ? accountLogo : accountBlackLogo} alt="Account Logo" className="w-8 h-8 rounded-full cursor-pointer" onClick={() => setActiveTab('account')}/>
                  <h1 className={`text-base ml-2 cursor-pointer ${activeTab === 'account' ? 'text-custom-brightgreen font-semibold' : ''}`} onClick={() => setActiveTab('account')}>Account Overview</h1>
                </div>
              </div>
              
              <button onClick={handleUpdateEvents} className="text-white self-center md:self-start bg-blue-500 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Update Events</button>
              {/* <button onClick={testEndpoint} className="text-white self-center md:self-start bg-blue-500 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Send Welcome Email</button> */}
              <Link to="/notifications" className="flex items-center self-center md:self-start hidden space-x-2 hover:bg-gray-100 p-2 rounded-md">
                <span>Notifications</span>
              </Link>
            </div>
            
          </div>
          {/* MEETINGS */}
          <div className="flex flex-col grow">
            <div>
            {activeTab === 'meetings' && (
              <div>
                <h1 className="text-xl md:text-3xl font-semibold text-left text-custom-darkblue my-8 ml-8 md:ml-0">Meetings</h1>
                <div className="xl:w-1/3 ml-8 flex">
                  <div className="bg-white shadow-md rounded-lg p-4 flex flex-col h-full sm:flex-row items-start sm:items-center overflow-hidden">
                    <div className="flex-grow text-left">
                      <div className="flex flex-row mb-4">
                        {/* REPLACE LOGO */}
                        <img src={videocamLogo} alt="Videocam Logo" className="w-6 h-6 rounded-lg mr-2 mb-1 self-center"/>
                        <div className="text-lg md:text-xl mb-2 text-custom-black">Meetings Held</div>
                      </div>
                      <h1 className="text-center font-semibold text-4xl md:text-5xl mb-4 md:mb-8">10</h1>
                    </div>
                  </div>
                </div>
                <h1 className="text-2xl md:text-3xl text-left text-custom-black my-4 md:my-8 ml-8 md:ml-0">Upcoming Meetings ({events.length})</h1>
                {loading ? (
                  <div role="status" className={`flex justify-center items-center h-64 transition-opacity duration-1000 ${showContent ? 'opacity-0' : 'opacity-100'}`}>
                    <svg aria-hidden="true" className="self-center w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className={`grid grid-cols-1 mx-8 lg:grid-cols-2 xl:grid-cols-3 transition-opacity duration-1000 ${showContent ? 'opacity-100' : 'opacity-0'}`}>
                  {events.length === 0 ? (
                    <div className="col-span-full flex flex-col items-center justify-center py-4 mb-32">
                      <img src={noEventsLogo} alt="No Events Logo" className="w-12 h-12 rounded-lg mt-32 mb-4"/>
                      <p className="text-xl text-custom-indexparagraph font-semibold">No upcoming meetings</p>
                    </div>
                  ) : (
                    events
                    .sort((a, b) => new Date(a.start) - new Date(b.start)) 
                    .map((event, index) => (
                      <div 
                        key={index}
                        className="flex flex-col shadow-xl p-2 mb-5 md:p-4 mr-2 sm:mr-4 md:mr-6 bg-white rounded-lg cursor-pointer hover:bg-custom-bg hover:shadow-lg transition-colors duration-300"
                        onClick={() => handleViewEventInsights(event)}
                      >
                        <div className="group bg-white hover:bg-custom-bg rounded-lg p-1 flex flex-col h-full sm:flex-row items-start sm:items-center overflow-hidden cursor-pointer  transition-colors duration-300">
                          <div className="flex-grow text-left">
                            <div className="font-bold text-md md:text-xl mb-1 md:mb-2 group-hover:text-custom-blue transition-colors duration-300">{event.name}</div>
                            <p className="text-sm text-custom-darkgrey mb-5 md:mb-1">
                              {getDisplayDate(event.start)}, {formatTime(event.start)} - {formatTime(event.end)}
                            </p>
                            <p className="text-gray-700 flex items-center">
                              <a 
                                href={event.hangout_link === "No Hangout Link" || !event.hangout_link ? "#" : event.hangout_link} 
                                className={`text-gray-700 flex items-center no-underline ${event.hangout_link === "No Hangout Link" || !event.hangout_link ? "opacity-50 cursor-not-allowed" : "hover:text-gray-900"}`}
                                aria-disabled={event.hangout_link === "No Hangout Link" || !event.hangout_link ? "true" : "false"}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <img src={attachmentLogo} alt="Attachment Icon" className="w-5 h-5 md:mr-2" />
                                <span className={`text-xs md:text-sm text-gray-700 transition-colors duration-300 ${event.hangout_link === "No Hangout Link" || !event.hangout_link ? "opacity-50 cursor-not-allowed" : "hover:text-custom-blue"}`}>
                                  {event.hangout_link || "No Hangout Link"}
                                </span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                )}
                <div className="mx-auto flex justify-center mt-6">
                  {currentPage > 1 && (
                    <Link to={`/dashboard?page=${currentPage - 1}`} className="mx-2">Previous</Link>
                  )}
                  <span>| Page {currentPage} of {totalPages} |</span>
                  {currentPage < totalPages && (
                    <Link to={`/dashboard?page=${currentPage + 1}`} className="mx-2">Next</Link>
                  )}
                </div>
              </div>
            )}
            {activeTab === 'account' && (
              <div>
                <h1 className="text-xl md:text-3xl font-semibold text-left text-custom-darkblue my-8 ml-8 md:ml-0">Account Overview</h1>
                <div className="flex flex-col">
                  <div className="flex flex-col xl:flex-row flex-wrap -mx-4">
                    <div className="w-full xl:w-1/2 p-4">
                      <div className="ml-8 md:ml-16">
                        <h1 className="text-xl md:text-2xl font-semibold text-custom-black mb-2">Profile</h1>
                        <div className="bg-white shadow-md rounded-lg p-1 md:p-4 flex flex-col h-full overflow-hidden">
                          <div className="text-sm md:text-lg">
                            <div className="px-3 md:px-6 mt-2 mb-0 md:mb-4 flex items-center justify-between">
                              <p className="text-custom-profiletext sm:text-center">First Name</p>
                              <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                                  <p className="text-black">{userFirstName}</p>
                              </div>
                            </div>
                            <div className="px-3 md:px-6 mb-6 md:mb-10 flex items-center justify-between">
                              <p className="text-custom-profiletext sm:text-center">Last Name</p>
                              <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                                <p className="text-black">{userLastName}</p>
                              </div>
                            </div>
                            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                            <a href="" className="block">
                              <div className="px-3 md:px-6 mt-4 flex items-center justify-between rounded-lg cursor-pointer bg-white hover:bg-gray-100 transition duration-200">
                                <p className="text-custom-darkblue font-semibold sm:text-center">Edit Profile</p>
                                <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                                  <span className="hover:underline text-2xl text-custom-darkblue font-bold">></span>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full xl:w-1/2 p-4 hidden md:block">
                      <div className="ml-16">
                        <h1 className="text-2xl font-semibold text-custom-black mb-2">Account Details</h1>
                        <div className="bg-white shadow-md rounded-lg p-4 flex flex-col h-full overflow-hidden">
                          <div className="text-lg">
                            <div className="px-6 mb-4 mt-8 flex items-center justify-between">
                              <p className="text-custom-profiletext sm:text-center">Email</p>
                              <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                                  <p className="text-black">{userId}</p>
                              </div>
                            </div>
                            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                            <a href="" className="block">
                              <div className="px-6 mt-4 mb-8 flex items-center justify-between rounded-lg cursor-pointer bg-white hover:bg-gray-100 transition duration-200">
                                <p className="text-custom-darkblue font-semibold sm:text-center">Edit Email Address</p>
                                <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                                  <span className="hover:underline text-2xl text-custom-darkblue font-bold">></span>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-4 mt-4 md:mt-16">
                    <div className="w-full xl:w-1/2 p-4 md:p-0">
                      <div className="ml-8 md:ml-20">
                        <h1 className="text-xl md:text-2xl text-2xl font-semibold text-custom-black mb-2">Usage</h1>
                        <div className="bg-white shadow-md rounded-lg p-1 md:p-4 flex flex-col h-full overflow-hidden">
                          <div className="text-sm md:text-lg">
                            <div className="px-3 md:px-6 mt-4 mb-0 md:mb-4 flex items-center justify-between">
                              <p className="text-custom-profiletext sm:text-center">Company Records</p>
                              <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                                  <p className="text-black">96 / 100 left</p>
                              </div>
                            </div>
                            <div className="px-3 md:px-6 mb-4 flex items-center justify-between">
                              <p className="text-custom-profiletext sm:text-center">People Records</p>
                              <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                                <p className="text-black">50 / 100 left</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
            
          </div>
        </div>
        
      </div>
      {/* {showModal && (
          <Modal
              title="Event Updates Available"
              message="There are new updates to your calendar events. Would you like to update now?"
              onConfirm={handleUpdateEvents}
              onCancel={() => setShowModal(false)}
          />
      )} */}
      {/* <Footer />    */}
    </div>
  );
};

export default Dashboard;
